import React, { useEffect } from 'react';
import { graphql, navigate, PageProps } from 'gatsby';

import { Sections } from '@/components/sections/Section';
import { ButtonsSection } from '@/components/sections/buttons/Buttons';
import { CardsSection } from '@/components/sections/cards/Cards';
import { DividerSection } from '@/components/sections/divider/Divider';
import { GallerySection } from '@/components/sections/gallery/Gallery';
import { HeadlineSection } from '@/components/sections/headline/Headline';
import { HeroSection } from '@/components/sections/hero/Hero';
import { LinksSection } from '@/components/sections/links/Links';
import { LogoSection } from '@/components/sections/logo/Logo';
import { getPageOptions } from '@/utils/contentful';
import { ContentfulOption } from '@/types';
import { defaultState, useContext } from '@/components/app/context';

export interface ContentfulPageMeta {
  id: string;
  title: string;
}

export interface ContentfulPage {
  id: string;
  options?: ContentfulOption[];
  sections?: [
    | ButtonsSection
    | CardsSection
    | DividerSection
    | GallerySection
    | HeadlineSection
    | HeroSection
    | LinksSection
    | LogoSection,
  ];
  slug: string;
}

interface ContentfulPageDataType {
  contentfulPage: ContentfulPage;
}

type ContentfulPageProps = PageProps<ContentfulPageDataType>;

const ContentfulPage: React.FC<ContentfulPageProps> = ({
  data: { contentfulPage: page },
}) => {
  const [, dispatch] = useContext();
  useEffect(() => {
    if (page) {
      dispatch({
        type: `SetPageOptions`,
        payload: getPageOptions(page.options),
      });
    } else {
      navigate(`/`);
    }
    return () => {
      // Reset page options on destroy
      dispatch({
        type: `SetPageOptions`,
        payload: {
          ...defaultState.page.options,
        },
      });
    };
  }, [dispatch, page]);
  if (!page) {
    return <></>;
  }
  return <Sections sections={page?.sections || []} />;
};

export const query = graphql`
  query GetPage($id: String!) {
    contentfulPage(id: { eq: $id }) {
      ...Page
    }
  }
`;

export default ContentfulPage;
